import React from 'react';
import NextImage, { ImageProps } from 'next/image';

const Image = (props: ImageProps) => (
  <NextImage
    { ...props }
    unoptimized
  />
);

export default Image;
